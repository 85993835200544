import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';

// override documentation for react-toastify
// https://fkhadra.github.io/react-toastify/how-to-style/#how-to-style-with-styled-components
const StyledToastContainer = styled(ToastContainer)`
  &&&.Toastify__toast-container {
  }
  .Toastify__toast {
    display: flex;
    align-items: center;
    gap: 8px;
    background: ${({ theme }) => theme.background.primary};
    box-shadow: 0px 3px 8px 0px rgba(15, 18, 31, 0.24);
  }
  .Toastify__toast-body {
    display: flex;
    gap: 8px;
    font-family: var(--font-futura);
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.palette.pine.default};
  }
`;

export default StyledToastContainer;
