import { Province } from '@pinecorpca/evergreen';

export const enabledProvinces = [
  Province.Ontario,
  Province.BritishColumbia,
  Province.Alberta,
  Province.NewBrunswick,
  Province.NewfoundlandAndLabrador,
  Province.PrinceEdwardIsland,
  // Province.Saskatchewan, // TODO: enable this once we resolve regulator issues with Saskatchewan
  Province.NovaScotia,
  Province.Manitoba,
];
