// https://docs.datadoghq.com/real_user_monitoring/guide/monitor-your-nextjs-app-with-rum/?tab=npm
import config from '@config';
import { datadogRum } from '@datadog/browser-rum';
import { ENVIRONMENT } from '@models/enums';

if ([ENVIRONMENT.LIVE, ENVIRONMENT.DEV, ENVIRONMENT.PILOT].includes(config.ENVIRONMENT)) {
  datadogRum.init({
    applicationId: 'a468b785-adc0-40d3-bc2f-6acc542fe22f',
    clientToken: 'pubdb2d7a193bca589d9d273b85052a804f',
    site: 'datadoghq.com',
    service: 'bellwoods',
    env: config.ENVIRONMENT,
    version: config.RELEASE_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    silentMultipleInit: true,
    defaultPrivacyLevel: 'allow',
    allowedTracingUrls: [
      {
        match: /https:\/\/.*\.pine\.ca\/api/,
        propagatorTypes: ['tracecontext'],
      },
    ],
  });
}

const DataDogInit = () => {
  // Render nothing - this component is only included so that the init code
  // above will run client-side
  return null;
};

export default DataDogInit;
