import { TFunction } from 'next-i18next';
import { MAX_PERCENT_PURCHASE_PRICE, MIN_PERCENT_PURCHASE_PRICE } from '../models/constraints';

export const validateMinDownPayment = (purchasePrice: number, t: TFunction): { value: number; message: string } => {
  if (purchasePrice < MIN_PERCENT_PURCHASE_PRICE) {
    return {
      value: purchasePrice * 0.05,
      message: t('DOWNPAYMENT_ERROR', { percent: 5 }),
    };
  }

  if (purchasePrice >= MIN_PERCENT_PURCHASE_PRICE && purchasePrice < MAX_PERCENT_PURCHASE_PRICE) {
    const minPercentAmount = MIN_PERCENT_PURCHASE_PRICE * 0.05;
    const remainingPercentAmount = (purchasePrice - MIN_PERCENT_PURCHASE_PRICE) * 0.1;
    return {
      value: minPercentAmount + remainingPercentAmount,
      message: t('DOWNPAYMENT_ERROR_2'),
    };
  }

  return {
    value: purchasePrice * 0.2,
    message: t('DOWNPAYMENT_ERROR', { percent: 20 }),
  };
};
